import React, { useState, useEffect } from "react"
import Breadcrumbs from "../breadcrumbs"
import ContentLoader from "react-content-loader"
import { connect } from "react-redux"
import { Link, navigate } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Image_Finger from "../../../images/circular.png"
import Image_Circular from "../../../images/img-1-legado.png"
import NoveltyCard from "../novelty-card"
import SVG from '../../../images/firma.png'
import parse from "html-react-parser"
import {
  getTextShort,
  getDateTime,
  getFeatured,
  getThree,
  avoidDuplicated
} from "../../../helpers/helper.novelty"
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { useGetBlogsQuery, useGetDetailBlogQuery } from "../../../redux/mediacore/blog"
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css';

const Main = ({id}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        name
        global_email
        global_phone
        keys {
          captcha
        }
        social {
          facebook
          instagram
        }
        branch_office {
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          services {
            title
            subtitle
            paragraph
          }
          footer {
            title_contact
            button_contact {
              value
              link
            }
            title_office
            title_social
            title_newsletter
          }
        }
      }
    }
  `)

  const { data: BlogDetailData, isLoading: isLoadingDetailData, isError: isErrorDetail,isFetching } = useGetDetailBlogQuery({"CLIENTID": 26, "BLOG_ID": id});
  const { data: allBlogData, isLoading: isLoadingBlogData,isError: isErrorBlogs } = useGetBlogsQuery(26);

  const imagesToShow = BlogDetailData?.images_data?.map((image) => ({
    original: image.file,
    thumbnail: image.file,
    description: image.text_alt,
    text_alt: image.text_alt,
  }));

  const [isCircular, setIsCircular] = useState(
    BlogDetailData?.tags?.find(element => element.name.toLowerCase() === "circular")
  )

  useEffect(() => {
    const secondDiv = document.getElementById("second_div")
    const contentDetail = document.getElementsByClassName("content-detail")[0]
    const header = document.getElementById("header")

    function handleScroll() {
      const topOfContentDetail = contentDetail.offsetTop - header.offsetHeight
      // function handleScroll() {
      if (window.pageYOffset > topOfContentDetail) {
      // if (window.pageYOffset + 290 > contentDetail.offsetHeight) {
        secondDiv.classList.add("position-lg-fixed")
      } else {
        secondDiv.classList.remove("position-lg-fixed")
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (BlogDetailData) {
      setIsCircular(
        BlogDetailData?.tags?.find(
          element => element.name.toLowerCase() === "circular"
        )
      )
      window.scrollTo(0, 0)
    }

  }, [BlogDetailData,allBlogData])

  useEffect(() => {
    if(isErrorDetail || isErrorBlogs || (!isFetching && !isLoadingDetailData && !BlogDetailData?.id)){
      navigate("/404")
    }
  },[isErrorDetail,isErrorBlogs,isFetching,isLoadingDetailData])

  return (
    <section className={"novelty-featured " + (isLoadingDetailData ? ' loading ' : '') + (isCircular ? " circular" : "")}>
      <div className={"jumbotron " + (isLoadingDetailData ? ' skeleton ' : '')}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 content-jumbotron">
              <div className="content-shadow position-relative">
                <img className="pt-4 image-novelty" src={BlogDetailData?.image} alt="Imagen portada de la novedad" />
              </div>
              <div className="content-text white">
                <span className="date text-uppercase">
                  {getDateTime(BlogDetailData?.date_published, true)}
                </span>
                <h2 className="title mt-lg-5 mt-4">{BlogDetailData?.title}</h2>
                <h3 className="subtitle mt-0 mb-lg-5 mb-5">
                  {getTextShort(BlogDetailData?.headline, 500)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-lg-5 pt-5 pb-0 my-lg-3 text-center w-100 d-flex justify-content-center">
        <Breadcrumbs
          props={[
            { name: realEstate.name, route: "/", location: "" },
            { name: isCircular ? 'Legado' : "Novedades", route: isCircular ? '/legado' : "/novedades", location: "" },
            {
              name: BlogDetailData?.title && getTextShort(BlogDetailData?.title, 40),
              route: "",
              location: "",
            },
          ]}
        />
      </div>
      <div className="content-detail">
        <div className="container-fluid">
          <div
            className={"row " + (isCircular ? "justify-content-between" : "")}
          >
            <div className={isCircular ? "col-lg-1 d-none d-lg-block col-6 order-lg-1" : "d-none"}>
              <img src={Image_Finger} alt="Imagen dedo" />
            </div>
            <div className={isCircular ? "col-lg-1 d-none d-lg-block col-6 order-lg-3" : "d-none"}>
              <img src={Image_Circular} alt="Imagen de circular" />
            </div>
            <div
              className={
                (getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular')))?.length === 0 && !isCircular ? 'col-12 col-lg-8 offset-lg-2' : 'col-lg-8 col-12') +
                " first_div  " +
                (isCircular ? "order-lg-2" : "order-lg-1")
              }
            >
              {isLoadingBlogData ? (
                <>
                  <div className="d-block d-lg-none">
                    <ContentLoader
                      speed={2}
                      width={"100vw"}
                      height={"100vh"}
                      viewBox="0 0 100% 100vh"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="1"
                        y="104"
                        rx="2"
                        ry="2"
                        width="80vw"
                        height="84"
                      />
                      <rect
                        x="-6"
                        y="4"
                        rx="0"
                        ry="0"
                        width="70vw"
                        height="16"
                      />
                      <rect
                        x="-1"
                        y="63"
                        rx="0"
                        ry="0"
                        width="70vw"
                        height="24"
                      />
                      <rect
                        x="-17"
                        y="204"
                        rx="2"
                        ry="2"
                        width="40vw"
                        height="62"
                      />
                      <rect
                        x="0"
                        y="299"
                        rx="0"
                        ry="0"
                        width="60vw"
                        height="19"
                      />
                      <rect
                        x="0"
                        y="351"
                        rx="2"
                        ry="2"
                        width="80vw"
                        height="50vh"
                      />
                    </ContentLoader>
                  </div>
                  <div className="d-none d-lg-block">
                    <ContentLoader
                      speed={2}
                      width={"100vw"}
                      height={"100vh"}
                      viewBox="0 0 100% 100vh"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="1"
                        y="104"
                        rx="2"
                        ry="2"
                        width="45vw"
                        height="84"
                      />
                      <rect
                        x="-6"
                        y="4"
                        rx="0"
                        ry="0"
                        width="280"
                        height="16"
                      />
                      <rect
                        x="-1"
                        y="63"
                        rx="0"
                        ry="0"
                        width="178"
                        height="24"
                      />
                      <rect
                        x="-17"
                        y="204"
                        rx="2"
                        ry="2"
                        width="40vw"
                        height="62"
                      />
                      <rect
                        x="0"
                        y="299"
                        rx="0"
                        ry="0"
                        width="128"
                        height="19"
                      />
                      <rect
                        x="0"
                        y="351"
                        rx="2"
                        ry="2"
                        width="45vw"
                        height="50vh"
                      />
                    </ContentLoader>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={
                      "div_header_novelty col-12" +
                      (isCircular || getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular')))?.length === 0 ? " col-lg-12" : " col-lg-11")
                    }
                  >
                    {BlogDetailData?.author_choices &&
                    BlogDetailData?.author_choices !== "unspecified" ? (
                      <span className={"name " + BlogDetailData?.author_choices}>
                        <b> Publicado por </b> {BlogDetailData?.author}
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={
                      "col-12 div_body_novelty mt-lg-4 mt-4 " +
                      (isCircular || getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular')))?.length === 0 ? " col-lg-12" : " col-lg-11")
                    }
                  >
                    {BlogDetailData?.content ? (
                      <div> {parse(BlogDetailData?.content)}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={"col-12 div_gallery  mt-lg-4 mt-4" + (isCircular || getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular')))?.length === 0 ? " col-lg-12" : " col-lg-11")}>
                    {!!imagesToShow?.length && (
                      <ImageGallery showPlayButton={false} showIndex
                        showBullets autoPlay items={imagesToShow} />
                    )}
                    {!!BlogDetailData?.files_data?.length > 0 ? (
                      <div className="div_header_novelty mt-5">
                        <h4 className="subtitle">Archivos para descargar</h4>
                        <div className="with-bt" />
                        {BlogDetailData?.files_data?.map((files) => (
                          <div key={files.id} className="linkStyled">
                            <a href={files.file} className="download_link">
                              <i className="icon-download-solid"></i>
                              {files.file.substring(files.file.indexOf('news/'), files.file.length).substring(5)}
                            </a>
                          </div> ))}
                      </div> ) : null }
                  </div>
                  <div
                    className={
                      "div_footer_novelty col-12" +
                      (isCircular || getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular')))?.length === 0 ? "col-lg-12 " : " col-lg-11")
                    }
                  >
                    <div className="d-flex align-items-center mt-5 justify-content-between">
                      <div className="div_pills mt-0 d-flex flex-wrap">
                        {BlogDetailData?.tags?.map((tag, index) => (
                          <span className="pill btn btn-blue me-2">
                            #{tag.name}
                          </span>
                        ))}
                      </div>
                      <div className="d-flex shared-buttons mb-auto justify-content-center align-items-center">
                        <span className="me-lg-4 me-3">COMPARTIR: </span>
                          <WhatsappShareButton
                              className="mx-1 WhatsappShareButton me-2 me-lg-3"
                              url={location?.href}
                              title={BlogDetailData?.title}
                              separator=":: "
                          >
                              <WhatsappIcon size={28} bgStyle={{fill:"#0b0b0b"}} />
                          </WhatsappShareButton>
                          <EmailShareButton 
                              className="mx-1 EmailShareButton me-2 me-lg-3"
                              body={BlogDetailData?.title + " " + location?.href} 
                              subject={BlogDetailData?.title} >
                              <EmailIcon size={28} bgStyle={{fill:"#0b0b0b"}} />
                          </ EmailShareButton>
                          <FacebookShareButton
                              className="mx-1 FacebookShareButton me-2 me-lg-3"
                              url = {location?.href}
                              quote={BlogDetailData?.title}>
                              <FacebookIcon size={28} bgStyle={{fill:"#0b0b0b"}} />
                          </FacebookShareButton>
                          <TwitterShareButton
                              className="mx-1 TwitterShareButton me-2 me-lg-3"
                              url={location?.href}
                              title={BlogDetailData?.title}
                          >
                              <TwitterIcon size={28} bgStyle={{fill:"#0b0b0b"}} />
                          </TwitterShareButton>
                          <LinkedinShareButton 
                              url={location?.href} 
                              source={location?.href} 
                              title={BlogDetailData?.title}
                              className="mx-1 LinkedinShareButton me-2 me-lg-3">
                              <LinkedinIcon size={28} bgStyle={{fill:"#0b0b0b"}} />
                          </LinkedinShareButton>
                      </div>
                    </div>
                    <div class={" col-12 mt-5 mb-5 mb-lg-0 text-center text-lg-start " + (isCircular && " d-none")}>
                      <Link
                        to={"/novedades"}
                        class="btn btn-blue"
                      >
                        Volver a las novedades
                      </Link>
                    </div>
                    <img src={SVG} className={"mt-5 " + (!isCircular && 'd-none')} alt="SVG decorativo circular" />
                    <Link className={"btn btn-blue mt-4 mx-auto " + (!isCircular && 'd-none')} to="/legado" >Volver a Circular Bullrich</Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Destacadas  */}
        <div id="second_div" className={"second_div " + (isCircular || getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular')))?.length === 0 ? "d-none" : "")}>
          <div className="row text-center">
            <h3 className="mb-5 title-row">
              {getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular')))?.length === 0 && !isLoadingBlogData
                ? ""
                : "NOVEDADES DESTACADAS"}
            </h3>
            <div className="col-12">
              {isLoadingBlogData
                ? [1, 2, 3].map(element => (
                    <div className="mb-3">
                      <ContentLoader
                        speed={2}
                        width={"100%"}
                        height={"45vh"}
                        viewBox="0 0 100% 45vh"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="-6"
                          y="4"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    </div>
                  ))
                : avoidDuplicated(BlogDetailData, getFeatured(allBlogData?.news?.filter(n => n.tags.find(t => t.name.toLowerCase() !== 'circular'))))?.map(novelty => (
                    <div className="col-12 mb-lg-1 mb-1">
                      <NoveltyCard skeleton={isLoadingDetailData ? true : false} novelty={novelty} featured={true} />
                    </div>
                  ))}
            </div>
          </div>
        </div>
        {/* Destacadas Circular */}
        <section
          className={
            "featured-history justify-content-center " +
            (isCircular && getThree(
              getFeatured(
                allBlogData?.news?.filter(element =>
                  element.tags.find(
                    tag => tag?.name?.toLowerCase() == "circular"
                  )
                )
              )
            ).length > 0 ? "d-block" : "d-none")
          }
        >
          <h2 className="mb-lg-5 mb-4 text-center w-100">Historias destacadas</h2>
          <div className="d-flex flex-column flex-lg-row justify-content-center">
            {isLoadingBlogData
              ? [1, 2, 3].map(element => (
                  <div className="mb-3">
                    <ContentLoader
                      speed={2}
                      width={"100%"}
                      height={"45vh"}
                      viewBox="0 0 100% 45vh"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="-6"
                        y="4"
                        rx="5"
                        ry="5"
                        width="100%"
                        height="100%"
                      />
                    </ContentLoader>
                  </div>
                ))
              : getThree(
                  getFeatured(
                    allBlogData?.news?.filter(element =>
                      element.tags.find(
                        tag => tag?.name?.toLowerCase() == "circular"
                      )
                    )
                  )
                )?.map(novelty => <NoveltyCard skeleton={isLoadingBlogData ? true : false} novelty={novelty} />)}
          </div>
          
        </section>
      </div>
    </section>
  )
}

export default connect(
  state => ({
    loading_novelties: state.allBlogData?.loading_novelties,
    loading: state.allBlogData?.loading,
    novelties: state.allBlogData?.novelties,
    novelty: state.allBlogData?.novelty,
  }),
  null
)(Main)
